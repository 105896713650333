html, body, #root {
    /* background-color: #b3966e; */
    height: 100%;
    
}

.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 30px; */
    justify-content: space-between;
    height: 100%;
}

.logo {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-width: 400px;
        width: 100%;
        /* margin-top: 40px; */
        margin-left: 15px;
        margin-right: 15px;
    }
}

.address {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #b3966e;
    color: white;
    /* height: 20%; */
    text-align: center;
    width: 100vw;
    padding: 20px 0px;
    p {
        margin: 5px;
    }
}

.contactForm {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
        text-align: center;
    }
    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        .inputGroup {
            display: flex;
            padding-bottom: 5px;
            position: relative;
            label {
                font-size: 16px;
                width: 100px;
                // padding-left: 20px;
            }
            input, textarea {
                font-size: 16px;
                width: 200px;
                border: none;
                background-color: #E8E8E8;
            }
            textarea {
                height: 70px;
                font-family: Arial, Helvetica, sans-serif !important;
            }

            .errorMessage {
                right: -5px;
                -webkit-transform: translate(100%, 0);
                position: absolute;
                color: #A20021;
            }

            &.captcha {
                padding-top: 10px;
            }
        }

        button {
            padding: 10px 15px;
            font-size: 16px;
            background-color: #b3966e;
            border: none;
            color: white;
            margin-top: 10px;
        }
        button:hover {
            cursor: pointer;
        }
    }
    .successMsg {
        text-align: center;
    }
    
}

.navBtn {
    font-size: 14px;
    position: absolute;
    left: 5px;
    top: 5px;
    border:none;
    background-color: transparent;
    &:hover {
        cursor: pointer;
    }
}